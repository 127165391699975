export const REACT_APP_NAME =
  "BikeGrid | Cycling Grid Game by Escape Collective";
export const REACT_APP_DESC =
  "Play the popular new Cycling Trivia game - BikeGrid! Complete your grid using any current pro cyclists and earn rarity points for unique picks.";
export const REACT_APP_URL = "https://bikegrid.escapecollective.com/";
export const REACT_APP_IMG_URL =
  "https://bikegrid.escapecollective.com/img/social.png";

export function addProductJsonLd() {
  return {
    __html: `{
      "@context" : "https://schema.org",
      "@type" : "WebSite",
      "name" : "BikeGrid",
      "url" : "https://bikegrid.escapecollective.com/"
    }
  `,
  };
}
